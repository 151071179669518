import React from "react";
import { useLocation } from "@reach/router";
import { Layout, Row, Col } from "antd";
import SEO from "../components/seo";
import NotFound from "../components/NotFound";

const { Content } = Layout;
const NotFoundPage = () => {
  return (
        <>
            <SEO title="Not Found Page" />

            <NotFound />

        </>
    )
};

export default NotFoundPage;
